import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Image from "gatsby-plugin-image"
import { slugify } from "../util/utilityFunctions"
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
  CardBody,
  Badge,
} from "reactstrap"

const getColor = (tag) => {
  if (tag === "Supplements") return "badge-supplements text-uppercase"
  if (tag === "Information") return "badge-info text-uppercase"
  if (tag === "Treatment") return "badge-treatment text-uppercase"
  if (tag === "Diet") return "badge-diet text-uppercase"
  if (tag === "Testimonial") return "badge-testimonial text-uppercase"
}

const Post = ({
  title,
  author,
  tags,
  path,
  date,
  body,
  fluid,
  sizes,
  slug,
}) => (
  <Link to={slug}>
    <Card className="blog-tiles">
      <CardBody>
        <Row>
          <Col sm="6">
            {sizes && <GatsbyImage image={sizes} />}
          </Col>
          <Col sm="6">
            <CardTitle>
              <h3>{title}</h3>
            </CardTitle>
            {/* <CardSubtitle>
              <small style={{textTransform: `uppercase`, fontSize:`.6rem`, color:`#bcbcbc`}}>{date}</small>
            </CardSubtitle> */}
            <CardText style={{marginBottom:`0`}}>{body}</CardText>
            <ul className="post-tags">
              {tags.map((tag) => (
                <li key={tag}>
                    <Link to={`/topic/${slugify(tag)}/`} className="tag-badge-post" style={{ color: `white` }}>
                      {tag}
                    </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Link>
)

export default Post
